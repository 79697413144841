<template>
    <div>
        <!--
            START Podatki o naročilu
        -->
        <div class="col-md-4">        

        
        <FormPanelSection
            title="Podatki o naročilu"
        >
            <!-- Naziv naročila -->
            <FormulateInput
                name="title"
                label="Naziv"
                type="text"
                :disabled="isReadOnly"
            />

            <!-- Datumi -->
            <h3 class="box-title">Datumi</h3><hr>
            <div class="row">
                <div class="col-sm-12">
                    <FormulateInput
                        name="created_at"
                        :label="$t('depository:client_submitted_date')"
                        type="datetime-local"
                        :disabled="true"
                        prependIconClass="ti-calendar"
                    />
                    <FormulateInput
                        name="due_date"
                        label="Rok izdelave"
                        type="date"
                        help="Datum, do katerega mora biti izdelana cenitev"
                        :disabled="isReadOnly"
                        :fromToday="true"
                        prependIconClass="ti-calendar"
                    />
                    <FormulateInput
                        v-if="showQuoteDueDate"
                        name="quote_due_date"
                        label="Rok za oddajo ponudb"
                        type="date"
                        help="Datum, do katerega morajo biti oddane ponudbe"
                        :disabled="isReadOnly"
                        :fromToday="true"
                        prependIconClass="ti-calendar"
                    />

                    <!--
                        START Order file
                    -->
                    <label>
                        Naročilnica
                        <HelpIconStatic
                            v-if="!isReadOnly"
                            title="Naročilnica"
                            text="Naložite lahko xlsx dokument v predpisani obliki, podatki pa se nato avtomatsko preberejo in izpolnijo v ustrezna polja znotraj tega naročila. Primer xlsx datoteke lahko prenesete <a href='https://podpora.arvio.si/wp-content/uploads/2025/01/eCenitve-primer-narocilnice.xlsx'>s te povezave</a>."
                        />
                    </label>

                    <FormulateInput
                        v-if="!isReadOnly"
                        type="file"
                        prependIconClass="ti-file"
                        accept=".xlsx"
                        class="m-b-10"

                        @handle-upload="handleUpload"
                    />

                    <div
                        v-if="formValues.order_file"
                        class="m-b-20"
                    >
                        <button
                            class="btn full-width breakable-button"
                            @click="onDownloadOrderFile"
                        >
                            Prenesi naročilnico {{ getOrderFileName }}
                        </button>
                        <button
                            v-if="!isReadOnly"
                            class="btn m-t-10 full-width breakable-button"
                            @click="$emit('import-order-file')"
                        >
                            Uvozi podatke iz naročilnice
                        </button>
                    </div>
                    <div v-else-if="isReadOnly" class="m-b-20">
                        Naročilnica ni bila naložena.
                    </div>
                    <!--
                        END Order file
                    -->

                </div>
            </div>

            <!-- Naročnik -->
            <h3 class="box-title">Naročnik</h3><hr>
            <div class="row">
                <div class="col-sm-12">
                    <FormulateInput
                        name="client_title"
                        :label="$t('depository:client_title')"
                        type="text"
                        help="Ime in priimek naročnika"
                        :disabled="isReadOnly"
                        prependIconClass="ti-user"
                    />
                </div>
                <div class="col-sm-12">
                    <FormulateInput
                        name="client_email"
                        :label="$t('depository:client_email')"
                        type="email"
                        help="Email naslov naročnika"
                        :disabled="isReadOnly"
                        prependIconClass="ti-email"
                        errorMessage="Email naslov ni veljaven"
                        pattern="^[a-zA-Z0-9._]+@[a-zA-Z0-9.]+.[a-zA-Z]{2,6}$"
                    />
                </div>
                <div class="col-sm-12">
                    <FormulateInput
                        name="client_phone"
                        :label="$t('depository:client_phone')"
                        type="tel"
                        help="Telefonska številka naročnika"
                        :disabled="isReadOnly"
                        prependIconClass="ti-headphone-alt"
                    />
                </div>
                <div class="col-sm-12">
                    <FormulateInput
                        name="client_address"
                        :label="$t('depository:client_address')"
                        type="text"
                        help="Naslov naročnika"
                        :disabled="isReadOnly"
                        prependIconClass="ti-home"
                    />
                </div>
            </div>

            <!-- Cenilci -->
            <template v-if="showProcurementForTeamSelection">
                <h3 class="box-title">Cenilci</h3><hr>
                <div class="row">
                    <div class="col-sm-12 m-b-20">
                        <label>Cenilci, ki jim bo povpraševanje vidno</label>
                        <div class="input-group">
                            <i class="input-group-addon ti-layers"></i>
                            <Multiselect
                                v-if="appraiser_team_options"
                                v-model="formValues.procurement_for_teams"
                                :options="appraiser_team_options"
                                :multiple="true"
                                :close-on-select="false"
                                :clear-on-select="false"
                                :placeholder="$t('general:select')"
                                :select-label="$t('general:select')"
                                :selected-label="$t('general:selected')"
                                :deselect-label="$t('general:remove')"

                                :disabled="isReadOnly"

                                label="label"
                                track-by="value"
                            >
                                <template slot="noResult">
                                    {{ $t('general:search:no_results')}}
                                </template>
                            </Multiselect>
                        </div>
                    </div>
                </div>
            </template>
        </FormPanelSection>

        </div>
        <!--
            END Podatki o naročilu
        -->

        <slot>
            <div><!-- This default slot solves a bug where FormValues was overriden unexpectedly. --></div>
        </slot>

        <!--
            START Predmet vrednotenja
        -->
        <div class="col-md-8">
            <FormPanelSection
                title="Predmet vrednotenja"
            >
                <OrderFormAppraisalTable
                    :orderitems="formValues.orderitem_set"
                    :isReadOnly="isReadOnly"
                />
            </FormPanelSection>
        </div>
        <!--
            END Predmet vrednotenja
        -->
    </div>
</template>
<script>
import ApiService from "@/components/utils/api.service.js";
import Multiselect from 'vue-multiselect'
import OrderFormRealEstateTable from "./order_form_parts/OrderFormRealEstateTable.vue";
import OrderFormAppraisalTable from "./order_form_parts/OrderFormAppraisalTable.vue";
import FormPanelSection from "./FormPanelSection.vue";
import HelpIconStatic from "@/components/widgets/HelpIconStatic";

export default {
    components: {
        OrderFormAppraisalTable,
        OrderFormRealEstateTable,
        FormPanelSection,
        Multiselect,
        HelpIconStatic,
    },
    props: {
        isReadOnly: {
            type: Boolean,
            default: false,  
        },
        formValues: {
            type: Object,
            default: null,
        },
        showProcurementForTeamSelection: {
            type: Boolean,
            default: true,
        },
        showQuoteDueDate: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            loading_appraiser_team: false,
            appraiser_team_options: [],
            retrievedAppraiserTeams: false,
        }
    },
    computed: {
        getOrderFileName() {
            if (!this.formValues.order_file)
                return "";
            const url = new URL(this.formValues.order_file);
            return url.pathname.split('/').pop();
        },
    },
    methods: {
        onDownloadOrderFile: function() {
            window.open(this.formValues.order_file);
        },
        handleUpload: function(file) {
            this.$emit('uploaded-order-file', file);
        },
        retrievePossibleAppraisers: function() {
            this.loading_appraiser_team = true;
            ApiService
                .get("/streamline/api/order/appraiser/list")
                .then(res => {
                    if (res.data) {
                        const appraiserTeamOptions = res.data.map(option => ({
                            value: option.team.id,
                            label: option.team.company_name || option.team.name,
                        }));

                        const formattedAppraiserTeams = appraiserTeamOptions.filter(option => this.formValues.procurement_for_teams.includes(option.value));
                        this.formValues.procurement_for_teams = formattedAppraiserTeams;
                        this.appraiser_team_options = appraiserTeamOptions;
                    }
                })
                .finally(() => {
                    this.loading_appraiser_team = false;
                })
        },
    },
    watch: {
        formValues: {
            handler() {
                // mounted; but wait for formValues to load
                if (!this.isReadOnly && !this.retrievedAppraiserTeams) {
                    this.retrievePossibleAppraisers();
                    this.retrievedAppraiserTeams = true;
                }
            },
        },
    },
}

</script>

<style scoped>
.breakable-button {
    white-space: normal;
    word-break: break-word;
}
.form-section{
    background-color: #dedede;
    padding:20px 10px;
    margin:15px 0;
}
.checkbox-property {
    height: 45px;
}
.align-tr{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

}
</style>