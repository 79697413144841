<template>
    <span
        v-if="has_link"
        style="display: inline-block;"
        :href="href"
        :class="`open-modal mark-row custom-cursor-pointer circle circle-sm display-inline bg-${levelColor(kpi_value)}`"
        data-iframe="true" data-modal-width="95%" data-modal-height="90%" data-toggle="tooltip"
        v-tooltip="tooltipText"
    >
        {{kpi_value}}
    </span>
    <span
        v-else
        style="display: inline-block;"
        :class="`circle circle-sm display-inline bg-${levelColor(kpi_value)}`"
        v-tooltip="tooltipText"
    >
        {{kpi_value}}
    </span>
</template>

<script>
export default {
    name: "IconKPI",
    props: {
        has_link: {
            type: Boolean,
            default: false,
        },
        href: {
            type: String,
            default: "",
        },
        kpi_value: {
            type: String,
            required: true,
        },
        invalidToolipText: {
            type: String,
            default: "Napaka pri pridobivanju",
        },
    },
    computed: {
        tooltipText: function(){
            if (this.kpi_value === "F"){
                return this.invalidToolipText;
            }
        },
    },
    methods: {
        levelColor: function(letter){
            switch (letter) {
                case "A":
                    return "success";
                case "B":
                    return "warning";
                case "C":
                    return "danger";
                default:
                    return "light";
            }
        },
    },
}
</script>
