var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',{attrs:{"items":_vm.items,"loading":_vm.loading,"columns":_vm.columns_joined},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('span',{class:("badge badge-" + (_vm.getTaskStatusColor(item.task)) + " label")},[_vm._v(" "+_vm._s(item.task.status_display)+" ")])]}},{key:"id",fn:function(ref){
var item = ref.item;
return [(item.order)?_c('span',[_vm._v(" Naročilo "+_vm._s(item.order.id)+", ")]):_vm._e(),_vm._v(" cenitev "+_vm._s(item.id)+" ")]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.appraisal_date, false))+" ")]}},{key:"appraiser",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.task.created_by)?[_vm._v(" "+_vm._s(item.task.created_by.first_name)+" "+_vm._s(item.task.created_by.last_name)+" ")]:_vm._e()],2)]}},{key:"kpi_avm_value_difference",fn:function(ref){
var item = ref.item;
return [(item.kpi_avm_value_difference)?_c('icon-kpi',{attrs:{"has_link":item.analysis_id != null,"href":("/amas/analysis/" + (item.analysis_id) + "/transaction/sale/matrix?popup=true"),"kpi_value":item.kpi_avm_value_difference,"invalidToolipText":_vm.getInvalidTooltipText(item.task, 'Ni pridobljeno. Tržna vrednost mora biti izpolnjena.')}}):_c('span',[_vm._v("/")])]}},{key:"kpi_legal_status",fn:function(ref){
var item = ref.item;
return [(item.kpi_legal_status)?_c('icon-kpi',{attrs:{"has_link":item.analysis_id != null,"href":("/amas/analysis/" + (item.analysis_id) + "/legal?popup=true"),"kpi_value":item.kpi_legal_status,"invalidToolipText":_vm.getInvalidTooltipText(item.task)}}):_c('span',[_vm._v("/")])]}},{key:"kpi_public_road_access",fn:function(ref){
var item = ref.item;
return [(item.kpi_public_road_access)?_c('icon-kpi',{attrs:{"has_link":item.analysis_id != null,"href":("/amas/analysis/" + (item.analysis_id) + "/document/road-access?popup=true"),"kpi_value":item.kpi_public_road_access,"invalidToolipText":_vm.getInvalidTooltipText(item.task)}}):_c('span',[_vm._v("/")])]}},{key:"real_estates",fn:function(ref){
var item = ref.item;
return _vm._l((item.orderitemproperty_set),function(property,idx){return _c('div',{key:'item-realestate'+idx},[_vm._v(" "+_vm._s([property.re_key, property.address, property.country].filter(function (prop) { return prop; }).join(", "))+" ")])})}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('router-link',{staticClass:"btn btn-sm btn-info btn-outline",attrs:{"to":{ name: 'orderItemDetails', params: { orderItemId: item.id }}}},[_vm._v(" Odpri ")])],1)]}},_vm._l((_vm.$scopedSlots),function(inx,name){return {key:name,fn:function(data){return [_vm._t(name,null,null,data)]}}})],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }