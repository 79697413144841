<template>
    <div>
        <div v-if="loading" class="panel panel-default text-center">

            <div class="panel-wrapper collapse in">
                <div class="panel-body">
                    <div class="row">
                        <p>{{ $t('avm:get_adjustment_table')}}</p>
                        <i class="fa fa-spinner fa-spin"></i>
                    </div>
                </div>
            </div>

        </div>
        <div v-else class="row">
            <div class="table-responsive">
                <table class="table color-table inverse-table text-center" >
                    <thead>
                        <tr>
                            <th v-bind:colspan="numberOfCols" >
                            {{ $t('avm:real_estate:pool')}}
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td class="font-medium text-left">{{ $t('general:transaction')}}</td>
                            <td>{{ $t('avm:real_estate:appraised')}}</td>
                            <template v-if="!arePopupsDisabled">
                                <td v-bind:key="transaction.id" v-for="transaction in transactions">
                                    <span data-toggle="tooltip" :data-original-title="$t('avm:transaction:detail')" rel="tooltip">
                                        <a class="open-modal"
                                            v-bind:href="transactionDetailsUrl(transaction)" >
                                            #{{transaction.sale_object.transaction_id}}
                                        </a>
                                    </span>
                                </td>
                            </template>
                            <template v-else>
                                <td v-bind:key="transaction.id" v-for="transaction in transactions">
                                    #{{transaction.sale_object.transaction_id}}
                                </td>
                            </template>
                        </tr>
                        <tr>
                            <td class="font-medium text-left">{{ $t('avm:re_key')}}</td>
                            <td>
                                <a
                                    v-if="!arePopupsDisabled"
                                    class="open-modal"
                                    v-bind:href="realEstateDetailsUrl(session.main_property.real_estate.re_key)" >
                                    {{session.main_property.real_estate.re_key}}
                                </a>
                                <span v-else>{{session.main_property.real_estate.re_key}}</span>
                            </td>
                            <td v-bind:key="transaction.id" v-for="transaction in transactions">

                                <span v-if="transaction.re_key" class="re-key" data-toggle="tooltip" :data-original-title="$t('avm:real_estate:detail:tooltip')" rel="tooltip">
                                    <a class="open-modal"
                                        v-if="!arePopupsDisabled"
                                        v-bind:href="realEstateDetailsUrl(transaction.re_key)" >
                                        {{transaction.re_key}}
                                    </a>
                                    <span v-else>{{transaction.re_key}}</span>
                                </span>
                                <span v-else class="re-key cursor-help" data-toggle="tooltip" :data-original-title="$t('avm:real_estate:re_key:error')" rel="tooltip">
                                    /
                                </span>
                            </td>                      
                                                    
                        </tr>
                        <tr>
                            <td class="font-medium text-left">{{ $t('general:address')}}</td>
                            
                            <td>
                                <div v-if="session.main_property.real_estate.unit && session.main_property.real_estate.unit.address">
                                    {{session.main_property.real_estate.unit.address.full_address.replace(/(.*), (\d+) (.*)/, "$1, $3")}}
                                </div>
                            </td>
                            <td v-bind:key="transaction.id" v-for="transaction in transactions">
                                    {{transaction.sale_object.address}}
                            </td>                       
                        </tr>

                        <tr v-if="showRealEstatePhoto">
                            <td class="font-medium text-left">{{ $t('avm:real_estate:photo')}}</td>
                            <td></td>
                            <td v-bind:key="transaction.id" v-for="transaction in transactions">
                                <div v-if="transaction.streetview_image">
                                    <img class="img-responsive w-100" v-bind:src="transaction.streetview_image"/>
                                </div>
                                <div v-else-if="transaction.map_image">
                                    <img class="img-responsive w-100" v-bind:src="transaction.map_image"/>
                                </div>
                                <div v-if="transaction.gps">
                                    <a v-if="!arePopupsDisabled" v-bind:href="transactionMapAndStreetviewUrl(transaction)" type="button" class="btn btn-sm btn-outline btn-info m-t-10 waves-effect waves-light open-modal">{{ $t('avm:real_estate:photo:edit')}}</a>
                                    <span v-else>/</span>
                                </div>
                            </td>   
                        </tr>

                        <tr>
                            <td class="font-medium text-left">{{ $t('avm:info:source')}}</td>
                            <td><data-source dataKey="KN" convertKey="real_estate_market" :lastUpdated="true" /></td>
                            <td v-bind:colspan="numberOfCols-1">
                                <data-source dataKey="ETN" convertKey="transactions_market" :lastUpdated="true" />
                            </td>
                            
                            
                        </tr>
                        <tr>
                            <td class="font-medium text-left">{{ $t('factortype:size')}}</td>
                            <td>{{session.total_size|formatSize(2)}}</td>

                            <td @click="currentTransactionSize=toggleTransactionRow(transaction, currentTransactionSize) " v-bind:key="transaction.id + '_size'"  v-for="transaction in transactions" v-bind:class="{'td-selector': !isChangeTransactionSizeDisabled, blurred: sizeAdjustmentsLoading}" >

                                <span v-bind:class="{'font-medium': !isChangeTransactionSizeDisabled}">{{ transaction.size|formatSize(transaction.unit_sale ? 2 : 0) }}
                                    <i class="fa fa-info-circle" data-toggle="tooltip" v-bind:data-original-title="transaction.custom_size_note" v-if="transaction.custom_size_note"></i>
                                </span>

                                <unit-size-estimate-indicator
                                    v-if="transaction.sale_object.unit"
                                    :unit="transaction.sale_object.unit"
                                >                  
                                </unit-size-estimate-indicator>

                            </td>
                        </tr>
                        <tr v-if="currentTransactionSize && !isChangeTransactionSizeDisabled">
                            <td v-bind:colspan="numberOfCols" >
                                <transaction-size-modify
                                    :transaction="currentTransactionSize"
                                    @update-size-complete="recalculateAdjustments"
                                    @size-remains-same="closeTransactionSizeDetail"
                                />
                            </td>
                        </tr>

                        <tr>
                            <td class="font-medium text-left">{{ $t('general:year:built') }}</td>
                            <td v-if="session.main_property.year_built">
                            {{ session.main_property.year_built }}
                            </td>
                            <td v-else-if="session.main_property.real_estate.unit && session.main_property.real_estate.unit.building">
                                {{session.main_property.real_estate.unit.building.year_built }}
                            </td>
                            <td v-else>
                            /
                            </td>
                            <td v-bind:key="transaction.id" v-for="transaction in transactions">
                                {{ transaction.sale_object.building_year_built }}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-medium text-left">{{ $t('real_estate:renovations')}}</td>
                            <td>
                                <renovations-block
                                    v-if="session.main_property.real_estate.unit"
                                    :unit="session.main_property.real_estate.unit"
                                />
                            </td>
                            <td v-bind:key="transaction.id" v-for="transaction in transactions">
                                <renovations-block
                                    v-if="transaction.sale_object.unit"
                                    :unit="transaction.sale_object.unit"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td class="font-medium text-left">{{ $t('avm:legal:rights')}}</td>
                            <td>{{ $t('avm:legal:full')}}</td>
                            <td v-bind:key="transaction.id" v-for="transaction in transactions">
                                {{ $t('avm:legal:full')}}
                            </td>
                            
                        </tr>

                        <tr v-if="transactions.some(transaction => transaction.sale_object.unit_location)">
                            <td class="font-medium text-left">{{ $t('real_estate:unit:position') }}</td>
                            <td></td>
                            <td v-bind:key="transaction.id" v-for="transaction in transactions">
                                {{ transaction.sale_object.unit_location}}
                            </td>
                        </tr>

                        <tr>
                            <td class="font-medium text-left">{{ $t('avm:legal:sell_tos')}}</td>
                            <td>{{ $t('avm:legal:market')}}</td>
                            <td v-bind:key="transaction.id" v-for="transaction in transactions">
                                {{ $t('avm:legal:market')}}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-medium text-left">{{ $t('avm:transaction:type')}}</td>
                            <td></td>
                            <td v-bind:key="transaction.id" v-for="transaction in transactions">
                                {{transaction.get_transaction_type_display}}
                            </td>
                        </tr>

                        <tr v-if="transactions.some(transaction => transaction.sale_object.status_display)">
                            <td class="font-medium text-left">{{ $t("general:status") }}</td>
                            <td></td>
                            <td v-bind:key="transaction.id" v-for="transaction in transactions">
                                {{ transaction.sale_object.status_display }}
                            </td>
                        </tr>
                    </tbody>
                    
                    <thead v-if="transaction_type=='sale'">
                        <tr>
                            <th>
                            {{ $t('avm:adjustment') }}
                            </th>
                            <th v-bind:colspan="numberOfCols">
                                <div class="pull-right">

                                </div>
                            </th>
                        </tr>
                        
                    </thead>
                    <tbody v-if="transaction_type=='sale'">
                        <template  v-for="(factor, f_idx) in factors" >
                            <tr v-bind:key="'tr-'+f_idx"  >
                                <td  @click="toggleFactorType(factor)" class="font-medium text-left" v-bind:class="{'td-selector':canModifyFactorType(factor)}">
                                    {{factor.name}} 
                                    <help-icon class="pull-right" v-if="!factor.analysis_session" :slug="getSlug(factor.name_slug)" />
                                </td>
                                <td>
                                    {{factor.extract_original_value}}
                                </td>
                                
                                
                                <td class="td-selector" 
                                @click="toggleAdjustmentFactorDetail(getAdjustmentFactor(factor, transaction))"
                                v-bind:key="transaction.id"
                                v-bind:class="{ selected: compareAdjustmentFactors(getAdjustmentFactor(factor, transaction),currentAdjustment),
                                                    blurred: (compareAdjustmentFactors(getAdjustmentFactor(factor, transaction),currentAdjustment)
                                                    && !adjustmentTypeNotLoading) || sizeAdjustmentsLoading ||
                                                    (compareAdjustmentFactors(getAdjustmentFactor(factor, transaction),updatedAdjustmentFactor))}"
                                v-for="transaction in transactions">
                                        <factor-adjustment
                                        :adjustment="getAdjustmentFactor(factor, transaction)"
                                        :disabled="areExternalTransactionsUnavailable"
                                        :transaction="transaction"
                                        :factor="factor"
                                        @update-factor-size-complete="sizeAdjustmentUpdateComplete"
                                        @blur-factor="blurAdjustmentFactor"
                                        @adjustment-created="forceRefreshAdjustments"
                                        >
                                        </factor-adjustment>                                
                                </td>
                            </tr>

                            <tr v-bind:key="factor.value" class="" v-if="currentAdjustment != null && factor.id == currentAdjustment.factor_type.id ">
                                <td v-bind:colspan="numberOfCols" class="p-t-0 p-b-0">
                                    <b-collapse v-model="adjustmentTypeNotLoading" >
                                        <factor-adjustment-modify
                                        :adjustment="currentAdjustment"
                                        @update-complete="closeAdjustmentDetail"
                                        @adjustment-factor-type-loading="toggleAdjustmentTypeNotLoading"
                                        :disabled="areExternalTransactionsUnavailable"
                                            >
                                        </factor-adjustment-modify>
                                    </b-collapse>
                                </td>
                            </tr>
                        </template>
                        <tr>
                            <td class="td-selector" v-on:click="createFactorType">
                                <i class="fa fa-plus text-muted m-l-5 m-r-5"></i>
                            </td>
                            <td v-bind:colspan="numberOfCols-1">
                                
                            </td>
                        </tr>
                        <tr>
                            <th v-bind:colspan="numberOfCols" v-if="currentFactorType != null">
                                <factor-type-adjustment-modify
                                    :factor_type="currentFactorType"
                                    :analysis_session="session_id"
                                    @update-factortype-complete="closeFactorTypeDetail"
                                    :disabled="areExternalTransactionsUnavailable"
                                />
                            </th>
                        </tr>
                        
                    </tbody>
                    <thead>
                        <tr>
                            <th>{{ $t('avm:calculation')}} <help-icon class="pull-right" slug="prilagojena-vrednost-primerljive-transakcije" /></th>
                            <th v-bind:colspan="numberOfCols" ></th>
                        </tr>
                    </thead>

                    <tbody v-bind:class="{blurred: transactionLoading}">
                        <tr>
                            <td class="font-medium text-left">
                                {{ $t('avm:sell_price')}}
                                <help-icon class="pull-right" slug="transakcije-upostevani-ddv" />
                            </td>
                            <td>-</td>
                            <td v-bind:key="transaction.id" v-for="transaction in transactions">
                                {{transaction.price_gross|formatCurrency(0)}}
                            </td>
                        </tr>

                        <tr v-if="session.custom_price_zone || transactions.some(transaction => transaction.sale_object.price_zone)">
                            <td class="font-medium text-left">{{ $t('price_zone') }}</td>
                            <td>{{ session.custom_price_zone }}</td>
                            <td v-bind:key="transaction.id" v-for="transaction in transactions">
                                {{ transaction.sale_object.price_zone }}
                            </td>
                        </tr>

                        <tr>
                            <td class="font-medium text-left">{{ $t('avm:sell_price:m2')}}</td>
                            <td>-</td>
                            <td v-bind:key="transaction.id" v-for="transaction in transactions">
                                {{transaction.price_m2|formatCurrencym2()}}

                                <help-icon v-if="!transaction.sale_object.transaction_amount_m2" slug="ocenjena-vrednost-komponente" />
                            </td>
                        </tr>

                        <tr>
                            <td class="font-medium text-left">{{ $t('avm:adjustment:sum')}}</td>
                            <td>-</td>
                            <td v-bind:key="transaction.id" v-for="transaction in transactions">
                                {{transaction.adjusted_sum_perc|formatPercent(2)}}
                                |
                                {{transaction.adjusted_sum|formatCurrency(2)}}
                                <i v-if="transaction.is_adjusted_sum_perc_off" class="fa fa-exclamation-circle text-warning m-l-5 m-r-5"
                                    :data-original-title="$t('avm:adjustment:hand_check')" rel="tooltip"
                                ></i>                            
                            </td>
                            
                        </tr>

                        <tr>
                            <td class="font-medium text-left">{{ $t('avm:adjustment:price')}}</td>
                            <td>-</td>
                            <td v-bind:key="transaction.id" v-for="transaction in transactions">
                                    <span class="text-info">{{transaction.adjusted_price|formatCurrency(2) }}</span>
                            </td>
                        </tr>

                        <tr>
                            <td class="font-medium text-left">{{ $t('avm:adjustment:price:m2')}}</td>
                            <td>-</td>
                            <td v-bind:key="transaction.id" v-for="transaction in transactions">
                                    <span class="text-primary font-medium">{{transaction.adjusted_price_m2|formatCurrencym2(2) }}</span>
                            </td>
                        </tr>

                        <tr>
                            <td class="font-medium text-left">{{ $t('avm:adjustment:weight')}}
                                <help-icon class="pull-right" slug="izracun-koncne-vrednosti" />

                            </td>
                            <td>-</td>
                            <td @click="currentTransactionWeight=toggleTransactionRow(transaction, currentTransactionWeight)" v-bind:key="transaction.id + '_weight'" v-for="transaction in transactions" class="td-selector">
                                    <span class="font-medium">{{transaction.weight_raw}}</span>
                            </td>

                            
                        </tr>
                        <tr>
                            <td v-bind:colspan="numberOfCols" >
                                <transaction-weight-modify
                                    v-if="currentTransactionWeight"
                                    :transaction="currentTransactionWeight"
                                    @update-weight-complete="closeTransactionWeightDetail"
                                    :disabled="areExternalTransactionsUnavailable"
                                />
                            </td>
                            
                        </tr>
                    </tbody>

                </table>
            </div>
            <div class="row">
                <div v-bind:class="{blurred: transactionLoading}" class="col-md-6" >
                    <bar-widget
                        :title="barWidgetPriceM2Title"
                        :value="appraisedValueM2|formatCurrencym2(2)"
                        :barValues="getComparableTransactionPricesM2"
                    />
                </div>
                <div v-bind:class="{blurred: transactionLoading}" class="col-md-6">
                    <bar-widget
                        :title="barWidgetPriceTitle"
                        :value="appraisedValue|formatCurrency(2)"
                        :barValues="getComparableTransactionPrices"
                    />
                </div>
                
            </div>
        </div>  
    </div>
    
</template>


<script>
import ApiService from "@/components/utils/api.service.js"

import _UnitRenovationsBlock from './components/_UnitRenovationsBlock.vue';
import FactorAdjustment from './components/FactorAdjustment.vue';
import FactorAdjustmentModify from './components/FactorAdjustmentModify.vue';
import FactorTypeAdjustmentModify from './components/FactorTypeAdjustmentModify.vue';
import TransactionWeightModify from './components/TransactionWeightModify.vue';
import TransactionSizeModify from './components/TransactionSizeModify.vue';
import UnitSizeEstimateIndicator from "@apps/real_estate/vue/components/UnitSizeEstimateIndicator.vue"
import KGoogleStreetview from '@/components/k-google-map/KGoogleStreetview.vue'
import BarWidget from '@/components/widgets/BarWidget.vue';
import HelpIcon from "@/components/widgets/HelpIcon";
import 'vue-loading-overlay/dist/vue-loading.css';
import { Swal } from "@/components/utils/swal.alerts.js";

import countrymixin from "@/mixins/countrymixin";

export default {
    mixins: [ countrymixin ],
    components : {
        "renovations-block": _UnitRenovationsBlock,
        FactorAdjustment,
        FactorAdjustmentModify,
        FactorTypeAdjustmentModify,
        TransactionWeightModify,
        TransactionSizeModify,
        BarWidget,
        HelpIcon,
        UnitSizeEstimateIndicator,
        KGoogleStreetview
    },
    props : {
        session_id: {
            type: Number,
            required: true,
        },
        transaction_type: {
            type: String,
            default: "sale",
            validator: function (value) {
                return ["sale", "rent"].includes(value);
            },
        },
        valuation_method: {
            type: String,
            default: "comparables",
            validator: function (value) {
                return ["comparables", "cost"].includes(value);
            },
        },
    },
    methods : {
        getSlug(slug) {
            if (slug === "velicina")
                return "velikost";
            return slug;
        },
        compareAdjustmentFactors(af1, af2){
            return (af1 && af2 && 
            af1.factor_type.id == af2.factor_type.id &&
            af1.transaction.id == af2.transaction.id);
        },
        openFactorTypeDetail(factor){
            if(factor.analysis_session){
                this.currentFactorType = factor;
            }
        },
        getAdjustmentFactor(factor, transaction){
            
            let arr = this.adjustmentfactors.filter( x => 
                        x.factor_type &&
                        x.factor_type.id==factor.id &&
                        x.transaction &&
                        x.transaction.id == transaction.id);
            
            if (arr.length == 0){
                return {
                    factor_type: factor,
                    transaction: transaction
                };
            } else {
                return arr[0];
            }
        
        },
        toggleRow(incomingObject, currentObject){
            if (currentObject && currentObject.factor_type.id == incomingObject.factor_type.id && currentObject.transaction.id == incomingObject.transaction.id){
                return null;
            }else{
                return incomingObject
            }
        },
        toggleTransactionRow(incomingTransaction, currentTransaction){
            if (currentTransaction && currentTransaction.id == incomingTransaction.id){
                return null;
            } else {
                return incomingTransaction
            }
        },
        toggleFactorRow(incomingFactor, currentFactor){
            if (currentFactor && currentFactor.id == incomingFactor.id){
                return null;
            }else{
                return incomingFactor
            }
        },
        toggleAdjustmentFactorDetail(adjustment){
            this.currentAdjustment = this.toggleRow(adjustment, this.currentAdjustment);
        },
        canModifyFactorType(factor){
            return (factor && factor.analysis_session);
        },
        toggleFactorType(factor){
            if(factor && factor.analysis_session){
                this.currentFactorType = this.toggleFactorRow(factor, this.currentFactorType);
            }
        },
        closeAdjustmentDetail(adjustment){
            this.currentAdjustment = null;
            this.getAdjustments();
        },
        closeFactorTypeDetail(){
            this.currentFactorType = null;
            this.getAdjustments();
        },
        closeTransactionWeightDetail(){
            this.currentTransactionWeight = null;
        },
        closeTransactionSizeDetail(){
            this.currentTransactionSize = null;
        },
        async recalculateAdjustments(condition) {
            if (condition) {
                try {
                    this.toggleSizeAdjustmentsLoading(true);
                    this.currentTransactionSize = null;

                    await this.getAdjustments(true);
                } catch (error) {
                    this.toggleSizeAdjustmentsLoading(false);

                    Swal({
                            title: "",
                            text: this.$t('avm:error:msg'),
                            icon: "error",
                        })
                    throw error;
                }
            }
            else {
                this.currentTransactionSize = null;
                this.toggleSizeAdjustmentsLoading(false);
            }
        },
        sizeAdjustmentUpdateComplete(condition){
            this.toggleSizeAdjustmentsLoading(false);

            if (condition) {
                this.getAdjustments();
            }
        },
        forceRefreshAdjustments(){
            location.reload();
            this.$forceUpdate();
        },
        blurAdjustmentFactor(args){
          if (args['blur'] === true){
            this.updatedAdjustmentFactor = args['adjustment'];
          }else{
            this.updatedAdjustmentFactor = null;
          }
        },
        toggleLoading(stateBool){
            if (this.session){
                this.transactionLoading = stateBool;
            } else{
                this.loading = stateBool;
            }
        },
        toggleAdjustmentTypeNotLoading(loadingBool){
            this.adjustmentTypeNotLoading = !loadingBool;
        },
        toggleSizeAdjustmentsLoading(loadingBool){
            this.sizeAdjustmentsLoading = loadingBool;
        },
        getAdjustments(recalculate=false) {
            recalculate ? this.toggleLoading(false) : this.toggleLoading(true);

            let url = `/amas/analysis/analysis/api/${this.session_id}/comparables-matrix`;
            ApiService
                .get(url, {params: {transaction_type: this.transaction_type, valuation_method: this.valuation_method}})
                .then(response => {
                    this.toggleLoading(false);
                    this.toggleSizeAdjustmentsLoading(false);
                    this.session = response.data;
                    this.transactions = response.data.transactions;
                    this.factors = response.data.factors;
                    this.adjustmentfactors = response.data.adjustmentfactors;
                })
                .catch(error => {
                    this.toggleLoading(false);
                    this.toggleSizeAdjustmentsLoading(false);

                    Swal({
                        title: "",
                        text: this.$t('avm:error:msg'),
                        icon: "error",
                    })
                    throw error;
                });
        },
        getCenter(transaction) {
            return {
                lat: parseFloat(transaction.gps.lat),
                lng: parseFloat(transaction.gps.lng)
            }  
        },
        createFactorType() {
            let factorType = {
                analysis_session: this.session_id,
            }

            this.currentFactorType = factorType;
        },
        transactionMapAndStreetviewUrl(transaction){
            return `/amas/analysis/${this.session_id}/transaction/${transaction.id}/maps?popup=true`;
        },
        transactionDetailsUrl(transaction){
            return `/market/transaction/${transaction.sale_object.transaction_id}/details`;
        },
        realEstateDetailsUrl(reKey){
            return `/amas/real_estate/${reKey}/details/0/amas`;
        },
        fetchUserPermissions() {
            ApiService
            .get("/api/v1/current-user")
            .then(response => {
                this.areExternalTransactionsUnavailable = !response.data.all_permissions_list.includes('userprofile.access_to_external_transactions_db');
                this.isChangeTransactionSizeDisabled = !response.data.all_permissions_list.includes('comparison.change_transaction_size');
                this.arePopupsDisabled = !response.data.all_permissions_list.includes('real_estate.real_estate_tab');
            })
            .catch(error => {
                throw error;
            });
        },
    },
    computed: {
        numberOfCols() {
            if (!this.transactions){
                return 0;
            }
            return this.transactions.length + 2;
        },
        getComparableTransactionPrices: function(){
            return this.transactions.map(t => t.adjusted_price);
        },
        getComparableTransactionPricesM2: function(){
            return this.transactions.map(t => t.adjusted_price_m2);
        },
        appraisedValue: function(){
            let val;
            if( this.session ) {
                if (this.valuation_method == "cost") {
                    val = this.session.appraisal_cost_method_comparable_parcels_value
                } else {
                    val = this.transaction_type=="sale" ? this.session.appraised_comparable_value : this.session.appraised_value_rent;
                }
            }
            return val;
        },
        appraisedValueM2: function(){
            let val;
            if( this.session ) {
                if (this.valuation_method == "cost") {
                    val = this.session.appraisal_cost_method_comparable_parcels_value_m2
                } else {
                    val = this.transaction_type=="sale" ? this.session.appraised_comparable_value_m2 : this.session.appraised_value_rent_m2;
                }
            }
            return val;
        },
        barWidgetPriceM2Title() {
            let title;
            if (this.valuation_method == "cost") {
                title = this.$t("cost_method:parcel_value_m2")
            } else {
                title = this.transaction_type == "sale" ?  this.$t("analysis:appraisal_summary:appraised_market_value_m2") : this.$t("avm:avg:price:m2")
            }
            return title;
        },
        barWidgetPriceTitle() {
            let title;
            if (this.valuation_method == "cost") {
                title = this.$t("cost_method:parcel_value")
            } else {
                title = this.transaction_type == "sale" ?  this.$t("analysis:appraisal_summary:appraised_market_value") : this.$t("avm:avg:price")
            }
            return title;
        },
        showRealEstatePhoto: function(){
            for (const transaction of this.transactions)
                if (transaction.gps)
                    return true;
            return false;
        },
    },
    data () {
        return {
            updatedAdjustmentFactor: null,
            currentAdjustment: null,
            currentFactorType: null,
            currentTransactionWeight: null,
            currentTransactionSize: null,
            factors:null,
            transactions:null,
            adjustmentfactors:null,
            session:null,
            loading:true,
            transactionLoading:false,
            sizeAdjustmentsLoading:false,
            adjustmentTypeNotLoading:false,
            areExternalTransactionsUnavailable:false,
            arePopupsDisabled:false,
        }
    },
    mounted () {
        this.getAdjustments();
        this.fetchUserPermissions();
        this.$root.$on('adjustment-created', data => {
          this.forceRefreshAdjustments(data);
        });
    }


}
</script>
<style scoped>


.td-selector {
    cursor: pointer;
}
.selected{
    cursor: pointer;
    background: #EFEFEF;

}
.td-selector:hover, .export-option:hover{
    background: #EFEFEF;
    
}
.blurred {
    filter: blur(2px);
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    pointer-events: none;
    color: #AAAAAA !important;
    opacity: 0.5;
}
</style>

