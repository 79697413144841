<template>
    <filtered-table
        :items="items"
        :loading="loading"
        :columns="columns_joined"
        :additional_filters="additional_filters"
    >
        <template v-slot:title="{ item }">
            {{ getOrderTitle(item) }}
            <div class="text-muted">
                <span v-tooltip="'Datum naročila'" v-if="item.created_at">
                    {{ formatDate(item.created_at, true) }}
                </span>
            </div>
        </template>

        <template v-slot:status="{ item }">
            <a :href="trackStatusLink(item.id)" target="_blank" v-tooltip="'Sledenje statusa naročila'">
                    <span :class="getStatusBadgeClass(item.status)" style="font-size: 10px;">
                    {{ item.get_status_display }}
                </span>
            </a>

            <a
                v-on:click.prevent="$modal.show('share-link-modal-order-' + item.id);"
                v-tooltip="'Deli povezavo za sledenje'"
                href="#"
                class="m-l-10"
            >
                <i class="fa fa-link"></i>
            </a>

            <ShareLinkModal
                :url="trackStatusLink(item.id)"
                :name="'share-link-modal-order-' + item.id"
            >
                <template v-slot:description>
                    <div class="text-center m-b-10">
                        Za sledenje statusa naročila kopirajte povezavo in jo pošljite naročniku.
                    </div>
                </template>
            </ShareLinkModal>
        </template>

        <template v-slot:client="{ item }">
            <div>
                <span v-tooltip="'Naziv naročnika'" v-if="item.client_title">
                    {{ item.client_title }}
                </span>
            </div>
            
            <span class="text-muted" v-tooltip="'eMail naslov naročnika'" v-if="item.client_email">
                <i class="fa fa-envelope"></i>
                {{ item.client_email }}
            </span>
        </template>

        <template v-slot:appraisals_amount="{ item }">
            {{ item.orderitem_set.length }}
        </template>

        <template v-slot:due_dates="{ item }">
            <div>
                <span v-tooltip="'Rok za oddajo ponudb'" v-if="item.quote_due_date">
                    <i class="fa fa-inbox"></i>
                    {{ formatDate(item.quote_due_date) }}
                </span>
            </div>
            <div>
                <span v-tooltip="'Rok za oddajo cenitve'" v-if="item.due_date">
                    <i class="fa fa-paper-plane"></i>
                    {{ formatDate(item.due_date) }}
                </span>
            </div>
        </template>

        <template v-slot:offers_amount="{ item }">
            {{ item.offers_amount }}
        </template>

        <template v-slot:offer_status="{ item }">
            <div>
                <template v-if="!checkOrderStatus(item, orderStatuses.ACCEPTED)">
                    <div v-if="item.offer_status == true">
                        <i class="fa fa-check"></i>
                        Ponudba oddana
                    </div>
                    <div v-else-if="item.offer_status == false">
                        <i class="fa fa-times"></i>
                        Ponudba zavrnjena
                    </div>
                </template>
            </div>
        </template>

        <template v-slot:appraiser="{ item }">
            <span v-tooltip="'Naziv cenilca'" v-if="item.appraiser">
                {{ item.appraiser.first_name }} {{ item.appraiser.last_name }}
            </span>
            <span v-tooltip="'Naziv cenilca'" v-else-if="item.appraiser_team">
                {{ item.appraiser_team.company_name ? item.appraiser_team.company_name : item.appraiser_team.name }}
            </span>
            <span v-else></span>
        </template>

        <template v-for="(inx, name) in $scopedSlots" v-slot:[name]="data">
            <slot :name="name" v-bind="data"></slot>
        </template>
    </filtered-table>
</template>

<script>
import ApiService from "@/components/utils/api.service.js";

import FilteredTable from "./FilteredTable";
import tablemixin from "./mixins/tablemixin";
import orderstatusmixin from "./mixins/orderstatusmixin";
import ShareLinkModal from "@/components/widgets/ShareLinkModal.vue";
import { formatDate, getOrderTitle} from "./utils";

export default {
    mixins: [ tablemixin, orderstatusmixin ],
    components: {
        FilteredTable,
        ShareLinkModal,
    },
    data() {
        return {
            base_columns: [
                {
                    field: "title",
                    label: "Naročilo",
                    slot_name: "title",
                },
                {
                    field: "get_status_display",
                    label: "Status",
                    slot_name: "status",
                    filter: {
                        title: "Status naročila",
                        type: "multiselect",
                        value: [],
                    },
                },
                {
                    field: "client",
                    label: "Naročnik",
                    slot_name: "client",
                    sort_conversion: item => item.client_title,
                },
                {
                    field: "appraisals_amount",
                    label: "Število cenitev",
                    slot_name: "appraisals_amount",
                    sort_conversion: item => item.orderitem_set.length,
                    filter: {
                        title: "Skupno število cenitev v naročilu",
                        type: "number",
                        value: {
                            "from": null,
                            "to": null,
                        },
                    },
                },
                // {
                //     field: "client_title",
                //     label: "Naročnik",
                //     filter: {
                //         type: "multiselect",
                //         value: [],
                //     },
                // },
                // {
                //     field: "client_email",
                //     label: "Email naročnika",
                //     filter: {
                //         type: "multiselect",
                //         value: [],
                //     },
                // },
                // {
                //     field: "created_at",
                //     label: "Datum naročila",
                //     filter: {
                //         type: "date",
                //         value: {
                //             "from": null,
                //             "to": null,
                //         },
                //     },
                // },
                {
                    field: "due_dates",
                    label: "Rok ponudbe & izvedbe",
                    slot_name: "due_dates",
                    sort_conversion: item => item.due_date,
                },
                // {
                //     field: "due_date",
                //     label: "Rok izvedbe",
                //     filter: {
                //         type: "date",
                //         value: {
                //             "from": null,
                //             "to": null,
                //         },
                //     },
                // },
                // {
                //     field: "quote_due_date",
                //     label: "Rok za oddajo ponudbe",
                //     filter: {
                //         type: "date",
                //         value: {
                //             "from": null,
                //             "to": null,
                //         },
                //     },
                // },
                {
                    field: "offers_amount",
                    label: "Število ponudb",
                    slot_name: "offers_amount",
                    sort_conversion: item => item.offers_amount,
                    filter: {
                        type: "number",
                        value: {
                            "from": null,
                            "to": null,
                        },
                    },
                },
                {
                    field: "offer_status",
                    label: "Ponudba",
                    slot_name: "offer_status",
                    hidden: true,
                },  
                {
                    field: "appraiser",
                    label: "Izbrani cenilec",
                    slot_name: "appraiser",
                },
                {
                    field: "statistics",
                    label: "Statistika",
                    sortable: false,
                    hidden: true,
                },
                {
                    field: "actions",
                    label: "Akcija",
                    sortable: false,
                    slot_name: "actions",
                },
            ],
            additional_filters: [
                {
                    field: "created_at",
                    filter: {
                        title: "Datum naročila",
                        type: "date",
                        value: {
                            "from": null,
                            "to": null,
                        },
                    },
                },
            ]
        }
    },
    methods: {
        formatDate,
        getOrderTitle,
        trackStatusLink(order_id) {
            return ApiService.constructShareLinkUrl(`/streamline/tracking/${order_id}`);
        },
    }
}
</script>
