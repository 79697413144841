<template>
    <div>
        <div class="row streamline--statistics-graphs-container">

            <div class="col-sm-3">
                <div class="white-box">
                    <h3 class="box-title">Odprta naročila</h3>
                    <div class="text-right">
                        <h1>3</h1> </div> <span class="text-info">20%</span>
                    <div class="progress m-b-0">
                        <div class="progress-bar progress-bar-info" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style="width:20%;"> <span class="sr-only">20% Complete</span> </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="white-box">
                    <h3 class="box-title">Naročila v pregledu</h3>
                    <div class="text-right">
                        <h1>2</h1> </div> <span class="text-warning">20%</span>
                    <div class="progress m-b-0">
                        <div class="progress-bar progress-bar-warning" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style="width:20%;"> <span class="sr-only">20% Complete</span> </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="white-box">
                    <h3 class="box-title">Naročila z opozorili</h3>
                    <div class="text-right">
                        <h1>2</h1> </div> <span class="text-danger">20%</span>
                    <div class="progress m-b-0">
                        <div class="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style="width:20%;"> <span class="sr-only">20% Complete</span> </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="white-box">
                    <h3 class="box-title">Naročila v zamudi</h3>
                    <div class="text-right">
                        <h1>2</h1> </div> <span class="text-danger">20%</span>
                    <div class="progress m-b-0">
                        <div class="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style="width:20%;"> <span class="sr-only">20% Complete</span> </div>
                    </div>
                </div>
            </div>

            <!-- <div class="col-sm-4">
                <div class="white-box">
                    <ApexDonutChart
                        title="Status naročil"
                        :values="Object.values(ordersByStatus)"
                        :labels="Object.keys(ordersByStatus)"
                        :loading="loading_orders"
                    />
                </div>
            </div>

            <div class="col-sm-4">
                <div class="white-box">
                    <ApexDonutChart
                        title="Status cenitev"
                        :values="Object.values(orderItemsByStatus)"
                        :labels="Object.keys(orderItemsByStatus)"
                        :loading="loading_orders"
                    />
                </div>
            </div>

            <div class="col-sm-4">
                <div class="white-box">
                    <ApexBarChart
                        title="Pregled naročil"
                        :chartType="'bar'"
                        :values="barChartValues"
                        :loading="loading_orders"
                    />
                </div>
            </div> -->

        </div>

        <div class="white-box">
            <div class="text-right">
                <button class="btn btn-sm btn-info btn-outline" @click="openCreateOrderModal">
                    Novo naročilo
                </button>
            </div>
        </div>
        

        <base-order-table
            :items="orders"
            :columns="order_table_columns"
            :loading="loading_orders"
        >   
            <template v-slot:actions="{ item }">
                <div :class="[
                    'button-holder',
                    {
                        'btn-group dropdown': checkOrderStatus(item, orderStatuses.OPEN) || areAllAppraisalsFinished(item),
                    }]"
                >
                    <button
                        class="btn btn-sm btn-info btn-outline"
                        @click="openOrderDetailsModal(item)"
                    >
                        Odpri
                    </button>

                    <template v-if="checkOrderStatus(item, orderStatuses.OPEN) || areAllAppraisalsFinished(item)">
                        <div class="btn btn-sm btn-info btn-outline dropdown-toggle"
                            type="button" data-toggle="dropdown"
                        >
                            <span class="caret"></span>
                        </div>
                        <ul class="dropdown-menu" role="menu">
                            <li v-if="checkOrderStatus(item, orderStatuses.OPEN)">
                                <a @click="cancelOrder(item)" href="#">
                                    Prekliči naročilo
                                </a>
                            </li>
                            <li v-if="areAllAppraisalsFinished(item)">
                                <a @click="finishOrder(item)" href="#">
                                    Zaključi naročilo
                                </a>
                            </li>
                        </ul>
                    </template>
                </div>
            </template>

            <template v-slot:statistics="{ item }">
                <order-statistics
                    :order="item"
                />
            </template>
        </base-order-table>
        
        <!-- NOTE: This is the previously used solution, refactor! -->
        <!-- <div class="row">
            <div class="col-md-12">
            </div>

            <div class="col-md-6">
                <base-order-table
                    :items="orders"
                    @item-row-click="getOrderItems"
                >
                    <template v-slot:actions="{ item }">
                        <span>
                            <router-link
                                class="btn btn-default"
                                :to="{ name: 'orderDetails', params: { orderId: item.id }}"
                            >
                                Odpri
                            </router-link>
                        </span>
                    </template>
                </base-order-table>
            </div>

            <div class="col-md-6">
                <loading 
                    :active="loading_order_items"
                    :can-cancel="false" 
                    :is-full-page="false"
                />

                <base-order-item-table
                    :items="order_items"
                >
                    <template v-slot:actions="{ item }">
                        <div class="m-b-5">
                            <router-link
                                class="btn btn-xs btn-default"
                                :to="{ name: 'orderItemDetails', params: { orderItemId: item.id } }"
                            >
                                Odpri
                            </router-link>
                        </div>
                        
                        <div>
                            <a v-if="item.task.task_status.name !== 'In preparation'" class="btn btn-xs btn-default" href="#" @click.prevent="moveSessionToDelovnik">Prenesi v delovnik</a>
                        </div>
                    </template>
                </base-order-item-table>
            </div>
        </div> -->

        <!--
            START Modals
        -->
        <bootstrap3-modal
            title="Novo naročilo"
            name="createOrderModal"
            width="90%"
        >
            <CreateOrder
                @order-submitted="handleOrderSubmitted"
            />
        </bootstrap3-modal>

        <bootstrap3-modal
            :title="getOrderTitle"
            name="orderDetailsModal"
            width="90%"
        >
            <OrderDetails
                v-if="selected_order"
                :orderId="selected_order.id"
                @order-submitted="handleOrderSubmitted"
            />
        </bootstrap3-modal>
        <!--
            END Modals
        -->
    </div>
</template>

<script>
import ApiService from "@/components/utils/api.service.js"
import Bootstrap3Modal from "@/components/utils/Bootstrap3Modal.vue";
import { Swal } from "@/components/utils/swal.alerts.js";

import BaseOrderTable from "../../components/BaseOrderTable";
import BaseOrderItemTable from "../../components/BaseOrderItemTable";
import CreateOrder from "./CreateOrder.vue";
import OrderDetails from "./OrderDetails.vue";
import OrderStatistics from "../../components/OrderStatistics";
import ApexDonutChart from "../../components/charts/ApexDonutChart.vue";
import ApexBarChart from "../../components/charts/ApexBarChart.vue";

import orderstatusmixin from "../../components/mixins/orderstatusmixin.js";
import { getOrderTitle } from "../../components/utils.js";

export default {
    mixins: [ orderstatusmixin ],
    components: {
        BaseOrderTable,
        BaseOrderItemTable,
        CreateOrder,
        OrderDetails,
        ApexDonutChart, ApexBarChart,
        "bootstrap3-modal": Bootstrap3Modal,
        OrderStatistics,
    },
    data() {
        return {
            orders: [],
            loading_orders: false,
            order_items: [],
            loading_order_items: false,
            selected_order: null,

            order_table_columns: [
                {
                    field: "offers_amount",
                    hidden: true,
                },
                {
                    field: "statistics",
                    label: "Statistika",
                    sortable: false,
                    slot_name: "statistics",
                    hidden: false,
                },
            ],
        }
    },
    methods: {
        getOrders: function() {
            this.loading_orders = true;
            return ApiService
                .get("/streamline/api/order/list", {
                    params: { "process": "depository", "actor": "buyer" }
                })
                .then(response => {
                    this.orders = response.data;
                })
                .catch(error => {
                    Swal({
                        title: "Napaka pri pridobivanju naročil",
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
                .finally(() => {
                    this.loading_orders = false;
                })
        },

        // Modals
        openCreateOrderModal: function() {
            this.$modal.show("createOrderModal");
        },
        handleOrderSubmitted: function() {
            this.$modal.hide("createOrderModal");
            this.getOrders();
        },
        openOrderDetailsModal: function(order) {
            this.selected_order = order;
            this.$modal.show("orderDetailsModal");
        },

        // Order items
        cancelOrder: function(order) {
            Swal({
                title: "",
                text: "Naročilo bo preklicano, želite nadaljevati?",
                icon: "warning",
                confirmButtonText: "Da",
                cancelButtonText: "Ne",
                showCancelButton: true,
            }).then(val => {
                if (val.isConfirmed) {
                    return ApiService
                        .post(`/streamline/api/order/${order.id}/cancel/`)
                        .then(() => {
                            Swal({
                                title: "",
                                text: "Naročilo je bilo preklicano",
                                icon: "success",
                            });
                            this.getOrders();
                        })
                        .catch(error => {
                            Swal({
                                title: "Napaka pri preklicu naročila",
                                text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                                icon: "error",
                            });
                            throw error;
                        });
                }
            })
        },
        areAllAppraisalsFinished: function(order) {
            return order.orderitem_set.every(orderitem => orderitem.task.completed) &&
                !this.checkOrderStatus(order, this.orderStatuses.COMPLETED);
        },
        finishOrder: function(order) {
            Swal({
                title: "",
                text: "Naročilo bo zaključeno, želite nadaljevati?",
                icon: "warning",
                confirmButtonText: "Da",
                cancelButtonText: "Ne",
                showCancelButton: true,
            }).then(val => {
                if (val.isConfirmed) {
                    return ApiService
                        .post(`/streamline/api/order/${order.id}/complete/`)
                        .then(() => {
                            Swal({
                                title: "",
                                text: "Naročilo je bilo zaključeno",
                                icon: "success",
                            });
                            this.getOrders();
                        })
                        .catch(error => {
                            Swal({
                                title: "Napaka pri zaključku naročila",
                                text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                                icon: "error",
                            })
                            throw error;
                        })
                }
            })
        },
    },
    computed: {
        ordersByStatus() {
            // {status: count}
            let result = {};
            this.orders.forEach(order => {
                let status = order.status;
                if (result[status]) {
                    result[status] += 1;
                } else {
                    result[status] = 1;
                }
            });
            return result;
        },
        orderItemsByStatus() {
            // {status: count}
            let result = {};
            this.orders.forEach(order => {
                let order_items = order.orderitem_set;
                order_items.forEach(order_item => {
                    let task_status_name = order_item.task.task_status.name;
                    if (result[task_status_name]) {
                        result[task_status_name] += 1;
                    } else {
                        result[task_status_name] = 1;
                    }
                });
            });
            return result;
        },
        barChartValues() {
            return [{
                name: 'PRODUCT A',
                data: [44, 55, 41, 67, 22, 43]
            }, {
                name: 'PRODUCT B',
                data: [13, 23, 20, 8, 13, 27]
            }, {
                name: 'PRODUCT C',
                data: [11, 17, 15, 15, 21, 14]
            }, {
                name: 'PRODUCT D',
                data: [21, 7, 25, 13, 22, 8]
            }]
        },
        getOrderTitle() {
            if (!this.selected_order)
                return "Podrobnosti";
            return "Naročilo " + getOrderTitle(this.selected_order);
        },
    },
    mounted() {
        this.getOrders();
    }

}
</script>
