<template>
    <FormulateForm
        v-model="offerValues"
        @submit="submitOffer"
        class="flexo"
    >
        <div class="col-md-12">
            <FormPanelSection
                title="Oddaja ponudb"
            >
                <div class="col-sm-12">
                    <FormulateInput
                        name="offer_status"
                        label="Izberi akcijo"
                        type="select"
                        :disabled="isReadOnly"
                        prependIconClass="ti-settings"
                        :options="{1: 'Oddaj ponudbo', 2: 'Zavrni ponudbo'}"
                    />
                </div>
                <template v-if="offerValues.offer_status == 1">
                    <div class="col-sm-6">
                        <FormulateInput
                            name="due_date"
                            label="Rok izvedbe"
                            type="date"
                            :disabled="isReadOnly"
                            :fromToday="true"
                            prependIconClass="ti-calendar"
                            :help="dueDateHelpText"
                        />
                    </div>
                    <div class="col-sm-6">
                        <FormulateInput
                            name="price"
                            label="Cena"
                            type="number"
                            :disabled="isReadOnly"
                            appendIcon="€"
                            :required="true"
                        />
                    </div>
                </template>

                <div class="col-sm-12">
                    <FormulateInput
                        name="notes"
                        label="Opombe"
                        type="textarea"
                        :disabled="isReadOnly"
                        prependIconClass="ti-pencil"
                    />
                </div>

                <div class="col-sm-12">
                    <FormulateInput 
                        type="submit" label="Oddaj"
                    />
                </div>
            </FormPanelSection>
        </div>
    </FormulateForm>
</template>

<script>
import ApiService from "@/components/utils/api.service.js";
import { Swal } from "@/components/utils/swal.alerts.js";
import { formatDate } from "./utils.js";

import FormPanelSection from "./FormPanelSection.vue";

export default {
    name: "OrderOffer",
    components: {
        FormPanelSection,
    },
    props: {
        order: {
            type: Object,
            required: true,
        },
        // initial offer object
        offer: {
            type: Object,
            default: null,
        },
        isReadOnly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            offerValues: {},
        }
    },
    methods: {
        submitOffer: function(data) {
            const submitData = {
                ...data,
                order: this.order.id,
            }
            if (this.offer) {
                return ApiService
                    .put(`/streamline/api/order-offer/${this.offer.id}/`, submitData)
                    .then(() => {
                        Swal({
                            title: "",
                            text: "Ponudba posodobljena",
                            icon: "success",
                        })
                        this.$emit("offer-submitted");
                    })
                    .catch(error => {
                        Swal({
                            title: "Napaka pri posodabljanju ponudbe",
                            text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                            icon: "error",
                        })
                        throw error;
                    })
            }
            return ApiService
                .post(`/streamline/api/order-offer/`, submitData)
                .then(() => {
                    Swal({
                        title: "",
                        text: data.offer_status == 1 ? "Ponudba oddana" : "Ponudba zavrnjena",
                        icon: "success",
                    })
                    this.$emit("offer-submitted");
                    this.offerValues = {
                        "offer_status": 1,
                        "due_date": this.order.due_date,
                    };
                })
                .catch(error => {
                    const title = data.offer_status == 1 ? "Napaka pri oddaji ponudbe" : "Napaka pri zavrnitvi ponudbe";
                    Swal({
                        title: title,
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
        },
        parseOffer: function() {
            // parse the due_date if possible
            let due_date = "";
            if (this.offer.due_date) {
                const due_date_parts = this.offer.due_date.split(".");
                due_date = `${due_date_parts[2]}-${due_date_parts[1]}-${due_date_parts[0]}`;
            }

            this.offerValues = {
                offer_status: this.offer.offer_status,
                due_date: due_date,
                price: this.offer.price,
                notes: this.offer.notes,
            }
        },
    },
    computed: {
        dueDateHelpText() {
            let helpText = "Datum, do katerega bo cenitev izdelana"
            if (this.order.due_date) {
                helpText += ` Naročnik zahteva izdelavo do ${formatDate(this.order.due_date)}`;
            }
            return helpText;
        },
    },
    mounted() {
        if (this.offer) {
            this.parseOffer();
        } else if (this.order) {
            this.offerValues.due_date = this.order.due_date;
        }
    }
}
</script>
