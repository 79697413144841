<template>
    <base-table
        :items="items"
        :loading="loading"
        :columns="columns_joined"
    >
        <template v-slot:status="{ item }">
            <span :class="`badge badge-${getTaskStatusColor(item.task)} label`">
                {{ item.task.status_display }}
            </span>
        </template>

        <template v-slot:id="{ item }">
            <span v-if="item.order">
                Naročilo {{ item.order.id }},
            </span>
            cenitev {{ item.id }}
        </template>

        <template v-slot:created_at="{ item }">
            {{ formatDate(item.appraisal_date, false) }}
        </template>

        <template v-slot:appraiser="{ item }">
            <div>
                <template v-if="item.task.created_by">
                    {{item.task.created_by.first_name}} {{item.task.created_by.last_name}}
                </template>
            </div>
            
        </template>

        <template v-slot:kpi_avm_value_difference="{ item }">
            <icon-kpi
                v-if="item.kpi_avm_value_difference"
                :has_link="item.analysis_id != null"
                :href="`/amas/analysis/${item.analysis_id}/transaction/sale/matrix?popup=true`"
                :kpi_value="item.kpi_avm_value_difference"
                :invalidToolipText="getInvalidTooltipText(item.task, 'Ni pridobljeno. Tržna vrednost mora biti izpolnjena.')"
            />
            <span v-else>/</span>
        </template>

        <template v-slot:kpi_legal_status="{ item }">
            <icon-kpi
                v-if="item.kpi_legal_status"
                :has_link="item.analysis_id != null"
                :href="`/amas/analysis/${item.analysis_id}/legal?popup=true`"
                :kpi_value="item.kpi_legal_status"
                :invalidToolipText="getInvalidTooltipText(item.task)"
            />
            <span v-else>/</span>
        </template>

        <template v-slot:kpi_public_road_access="{ item }">
            <icon-kpi
                v-if="item.kpi_public_road_access"
                :has_link="item.analysis_id != null"
                :href="`/amas/analysis/${item.analysis_id}/document/road-access?popup=true`"
                :kpi_value="item.kpi_public_road_access"
                :invalidToolipText="getInvalidTooltipText(item.task)"
            />
            <span v-else>/</span>
        </template>

        <template v-slot:real_estates="{ item }">
            <div v-for="(property, idx) in item.orderitemproperty_set" :key="'item-realestate'+idx">
                {{ [property.re_key, property.address, property.country].filter(prop => prop).join(", ") }}
            </div>
        </template>

        <template v-slot:actions="{ item }">
            <span>
                <router-link
                    class="btn btn-sm btn-info btn-outline"
                    :to="{ name: 'orderItemDetails', params: { orderItemId: item.id }}"
                >
                    Odpri
                </router-link>
            </span>
        </template>

        <template v-for="(inx, name) in $scopedSlots" v-slot:[name]="data">
            <slot :name="name" v-bind="data"></slot>
        </template>
    </base-table>
</template>

<script>
import BaseTable from "./BaseTable";
import IconKPI from "./IconKPI";
import tablemixin from "./mixins/tablemixin";
import orderitemstatusmixin from "./mixins/orderitemstatusmixin";
import { formatDate } from "./utils";

export default {
    mixins: [ tablemixin, orderitemstatusmixin ],
    components: {
        BaseTable,
        "icon-kpi": IconKPI,
    },
    data() {
        return {
            base_columns: [
                {
                    field: "status",
                    label: "Status",
                    slot_name: "status",
                    sort_conversion: item => item.task.status_display,
                    filter: {
                        type: "multiselect",
                        value: [],
                    },
                },
                {
                    field: "id",
                    label: "ID cenitve",
                    slot_name: "id",
                },
                {
                    field: "real_estates",
                    label: "Nepremičnine",
                    slot_name: "real_estates",
                },
                {
                    field: "created_at",
                    label: "Datum cenitve",
                    slot_name: "created_at",
                    sort_conversion: item => item.task.created_date,
                },
                {
                    field: "appraiser",
                    label: "Cenilec",
                    slot_name: "appraiser",
                    sort_conversion: item => {
                        if (item && item.task && item.task.created_by && item.task.created_by.first_name)
                            return `${item.task.created_by.first_name} ${item.task.created_by.last_name}`;
                        return "";
                    },
                    filter: {
                        type: "multiselect",
                        value: [],
                    },
                },
                {
                    field: "kpi_avm_value_difference",
                    label: "Zanesljivost ocene",
                    slot_name: "kpi_avm_value_difference",
                    sort_conversion: item => item.kpi_avm_value_difference,
                    filter: {
                        type: "multiselect",
                        value: [],
                    },
                },
                {
                    field: "kpi_legal_status",
                    label: "Pravno stanje",
                    slot_name: "kpi_legal_status",
                    sort_conversion: item => item.kpi_legal_status,
                    filter: {
                        type: "multiselect",
                        value: [],
                    },
                },
                {
                    field: "kpi_public_road_access",
                    label: "Dostop",
                    slot_name: "kpi_public_road_access",
                    sort_conversion: item => item.kpi_public_road_access,
                    filter: {
                        type: "multiselect",
                        value: [],
                    },
                },
                {
                    field: "actions",
                    label: "Akcija",
                    sortable: false,
                    slot_name: "actions",
                },
            ],
        }
    },
    methods: {
        formatDate,
        getTaskStatusColor: function(task) {
            return task.task_status.name === this.orderItemStatuses.STATUS_IN_PREPARATION ?
                "purple" : task.task_status.color;
        },
        getInvalidTooltipText: function(task, text=null) {
            if (task.task_status.name === this.orderItemStatuses.STATUS_IN_PREPARATION) {
                return "Status bo viden, ko cenilec odda cenitev.";
            }
            return text;
        }
    },
}
</script>
